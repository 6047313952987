.form-themed table {
  color: var(--text-primary-color);
}
.form-themed table th,
.form-themed table td {
  font-size: 15px;
  font-weight: normal;
}
.form-themed table th {
  line-height: 60px;
}
.form-themed table td {
  line-height: 18px;
  padding: 0;
}
.form-themed .p-r-1 {
  padding-right: 1rem !important;
}
.form-themed label {
  margin-bottom: 5px;
}
.form-themed label.wrapperLabel {
  cursor: pointer;
}
.form-themed label.wrapperLabel:not(.checkboxLabel) {
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.form-themed label.wrapperLabel:not(.checkboxLabel) .wrapperText {
  display: block;
  order: -1;
}
.form-themed .btn[disabled],
.form-themed input[type=text][disabled],
.form-themed input[type=password][disabled],
.form-themed input[type=number][disabled],
.form-themed .btn.disabled,
.form-themed input[type=text].disabled,
.form-themed input[type=password].disabled,
.form-themed input[type=number].disabled,
.form-themed .btn[disabled]:hover,
.form-themed input[type=text][disabled]:hover,
.form-themed input[type=password][disabled]:hover,
.form-themed input[type=number][disabled]:hover,
.form-themed .btn.disabled:hover,
.form-themed input[type=text].disabled:hover,
.form-themed input[type=password].disabled:hover,
.form-themed input[type=number].disabled:hover,
.form-themed .btn[disabled]:active,
.form-themed input[type=text][disabled]:active,
.form-themed input[type=password][disabled]:active,
.form-themed input[type=number][disabled]:active,
.form-themed .btn.disabled:active,
.form-themed input[type=text].disabled:active,
.form-themed input[type=password].disabled:active,
.form-themed input[type=number].disabled:active {
  background-color: var(--ui-gray-darker);
  border-color: var(--ui-gray-light);
  color: var(--text-primary-color);
}
.form-themed .btn[disabled] + .wrapperText,
.form-themed input[type=text][disabled] + .wrapperText,
.form-themed input[type=password][disabled] + .wrapperText,
.form-themed input[type=number][disabled] + .wrapperText,
.form-themed .btn.disabled + .wrapperText,
.form-themed input[type=text].disabled + .wrapperText,
.form-themed input[type=password].disabled + .wrapperText,
.form-themed input[type=number].disabled + .wrapperText,
.form-themed .btn[disabled]:hover + .wrapperText,
.form-themed input[type=text][disabled]:hover + .wrapperText,
.form-themed input[type=password][disabled]:hover + .wrapperText,
.form-themed input[type=number][disabled]:hover + .wrapperText,
.form-themed .btn.disabled:hover + .wrapperText,
.form-themed input[type=text].disabled:hover + .wrapperText,
.form-themed input[type=password].disabled:hover + .wrapperText,
.form-themed input[type=number].disabled:hover + .wrapperText,
.form-themed .btn[disabled]:active + .wrapperText,
.form-themed input[type=text][disabled]:active + .wrapperText,
.form-themed input[type=password][disabled]:active + .wrapperText,
.form-themed input[type=number][disabled]:active + .wrapperText,
.form-themed .btn.disabled:active + .wrapperText,
.form-themed input[type=text].disabled:active + .wrapperText,
.form-themed input[type=password].disabled:active + .wrapperText,
.form-themed input[type=number].disabled:active + .wrapperText {
  color: var(--text-primary-color);
  cursor: auto;
}
.form-themed input[type=text],
.form-themed input[type=password],
.form-themed input[type=number] {
  background-color: var(--ui-gray);
  border-color: var(--ui-border-color);
  color: var(--text-primary-color);
  font-weight: normal;
}
.form-themed input[type=text]:active,
.form-themed input[type=password]:active,
.form-themed input[type=number]:active,
.form-themed input[type=text]:focus,
.form-themed input[type=password]:focus,
.form-themed input[type=number]:focus {
  background-color: var(--ui-gray-dark);
  border: 2px solid var(--active-color);
  box-shadow: none;
}
.form-themed input[type=text]:active + .wrapperText,
.form-themed input[type=password]:active + .wrapperText,
.form-themed input[type=number]:active + .wrapperText,
.form-themed input[type=text]:focus + .wrapperText,
.form-themed input[type=password]:focus + .wrapperText,
.form-themed input[type=number]:focus + .wrapperText {
  color: var(--active-color);
}
.form-themed .btn-primary {
  background-color: var(--active-color);
  border-collapse: var(--ui-border-color-active);
  color: var(--ui-text-color-active);
}
.form-themed .btn-primary:hover,
.form-themed .btn-primary:active,
.form-themed .btn-primary:focus,
.form-themed .btn-primary:focus:active {
  background-color: var(--ui-active-color);
  border-collapse: var(--ui-border-color-active);
  color: var(--ui-text-color-active);
}
.form-themed .form-control {
  background-color: #fff;
  border: 0;
  font-size: 14px;
  height: 30px;
  line-height: 16px;
  padding: 8px 9px 6px;
}
.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
}
.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(102,175,233,0.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(102,175,233,0.6);
}
.form-control::-ms-expand {
  border: 0;
  background-color: transparent;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: ;
  opacity: 1;
}
.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: ;
}
input[type=range] {
  display: block;
  width: 100%;
}
.nav-tabs {
  border-bottom: 0;
  margin-bottom: 3px;
  position: relative;
  z-index: 1;
}
.nav-tabs :hover {
  cursor: pointer;
}
.nav-tabs>li {
  font-size: 14px;
  font-weight: normal;
  height: 40px;
  line-height: 40px;
  margin-bottom: 0;
}
.nav-tabs>li>button {
  display: block;
  width: 100%;
  line-height: inherit;
  margin: 0;
  height: inherit;
  padding: 0 10px 0 10px;
  outline: none;
}
.nav-tabs>li>button:after {
  background-color: transparent;
  bottom: -3px;
  content: ' ';
  display: block;
  height: 3px;
  width: 100%;
}
.nav-tabs>li>button,
.nav-tabs>li.active>button,
.nav-tabs>li>button:hover,
.nav-tabs>li.active>button:hover,
.nav-tabs>li>button:active,
.nav-tabs>li.active>button:active,
.nav-tabs>li>button:focus,
.nav-tabs>li.active>button:focus {
  color: var(--active-color);
  background-color: transparent;
  border: 0;
}
.nav-tabs>li.active>button {
  font-weight: bold;
}
.nav-tabs>li.active>button:after {
  background-color: var(--active-color);
}
.nav {
  padding-left: 0;
  list-style: none;
}
.nav > li {
  position: relative;
  display: block;
}
.nav > li {
  float: left;
  margin-bottom: -1px;
}
.form-themed .state-error.wrapperLabel input + .wrapperText,
.state-error.wrapperLabel input + .wrapperText {
  color: var(--state-error-text);
}
.form-themed .state-error+.tooltip .tooltip-inner,
.state-error+.tooltip .tooltip-inner {
  color: #fff;
  background-color: var(--state-error-border);
}
.form-themed .state-error+.tooltip.top .tooltip-arrow,
.state-error+.tooltip.top .tooltip-arrow {
  border-top-color: var(--state-error-border);
}
.form-themed .state-error+.tooltip.right .tooltip-arrow,
.state-error+.tooltip.right .tooltip-arrow {
  border-right-color: var(--state-error-border);
}
.form-themed .state-error+.tooltip.bottom .tooltip-arrow,
.state-error+.tooltip.bottom .tooltip-arrow {
  border-bottom-color: var(--state-error-border);
}
.form-themed .state-error+.tooltip.left .tooltip-arrow,
.state-error+.tooltip.left .tooltip-arrow {
  border-left-color: var(--state-error-border);
}
.form-themed .state-error:not(.component-group).form-control,
.state-error:not(.component-group).form-control,
.form-themed .state-error:not(.component-group) .form-control,
.state-error:not(.component-group) .form-control {
  background-color: var(--state-error-border);
  border-color: var(--state-error-border);
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.flex-h {
  display: flex;
  flex-direction: row;
}
.flex-v {
  display: flex;
  flex-direction: column;
}
.flex-grow {
  flex-grow: 1;
}
.nowrap {
  white-space: nowrap;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
h1 {
  font-size: 36px;
}
h3,
h1 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  margin-top: 20px;
  margin-bottom: 10px;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.row {
  margin-right: -15px;
  margin-left: -15px;
}
.pull-left {
  float: left !important;
}
.pull-right {
  float: right !important;
}
.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.fade.in {
  opacity: 1;
}
a {
  color: #337ab7;
  text-decoration: none;
}
.UserPreferencesTabs {
  display: flex;
  flex-direction: column;
}
.UserPreferencesTabs__selector {
  border-bottom: 3px solid #000;
}
.UserPreferencesTabs .errorMessage {
  color: var(--state-error-text);
  font-size: 10px;
  text-transform: uppercase;
}
.UserPreferencesTabs .form-content {
  border-bottom: 3px solid var(--primary-background-color);
  margin-bottom: 20px;
  margin-left: -20px;
  margin-right: -20px;
  max-height: 70vh;
  overflow-y: auto;
  padding: 20px;
  min-height: 500px;
}
.UserPreferencesTabs .popover {
  width: 300px;
}
.presetIndex {
  padding: 0px 10px 0px 10px;
}
