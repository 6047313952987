.OHIFModal {
  background-color: var(--ui-gray-darker);
  border-color: var(--ui-border-color);
  color: var(--text-secondary-color);
  border-radius: 6px;
  border: 0;
  color: var(--text-primary-color);
  position: relative;
  -webkit-box-shadow: 0 3px 9px rgba(0,0,0,0.5);
  box-shadow: 0 3px 9px rgba(0,0,0,0.5);
  background-clip: padding-box;
  outline: 0;
}
@media (min-width: 320px) {
  .OHIFModal {
    width: 78%;
    min-width: 300px;
  }
}
@media (min-width: 768px) {
  .OHIFModal {
    width: 600px;
  }
}
@media (min-width: 992px) {
  .OHIFModal {
    width: 900px;
  }
}
.OHIFModal__content {
  padding: 20px;
  max-height: 90vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.OHIFModal__content::-webkit-scrollbar {
  display: none;
}
.OHIFModal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-bottom-color: #000;
  padding: 20px;
  position: relative;
}
.OHIFModal__header h4 {
  color: var(--text-secondary-color);
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  padding-right: 24px;
  margin: 0;
}
.OHIFModal__header button {
  cursor: pointer;
  margin: -10px 0 0 0;
  padding: 0;
  background-color: transparent;
  border: none;
  color: var(--text-secondary-color);
  font-size: 25px;
  font-weight: lighter;
}
.OHIFModal__header button:active,
.OHIFModal__header button:focus,
.OHIFModal__header button:focus:active {
  background-image: none;
  outline: 0;
  box-shadow: none;
}
