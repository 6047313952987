.HotKeysPreferences {
  display: flex;
}
.HotKeysPreferences .column {
  width: 50%;
  padding-right: 15px;
  padding-left: 15px;
}
.HotKeysPreferences .column-full {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
